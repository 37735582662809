.upload-preview {
	display: none;
}

.upload-label {
	cursor: pointer;
	position: absolute;
	top: 0;
	left: 0;
	border-radius: 50%;
	display: block;
	min-width: 150px;
	min-height: 150px;
	width: 150px;
	height: 150px;
}
