.checked-tasks-menu {
	position: fixed;
	display: flex;
	bottom: 25px;
	left: 50%;
	transform: translateX(-50%);
	z-index: 99999;
	background-color: $clr0;
	height: 63px;
	box-shadow: 0px 15px 50px rgba(0, 0, 0, 0.3);
	border-radius: 4px;

	.checked-tasks-count {
		display: flex;
		align-items: center;
		justify-content: center;
		font-size: rem(30px);
		color: $clr0;
		background-color: $bg-clr1;
		width: 63px;
		border-start-start-radius: 4px;
		border-end-start-radius: 4px;
	}

	.close-checked-modal {
		width: 63px;
		border-inline-start: 2px solid $input-hover-border-clr;
		display: flex;
		justify-content: center;
		align-items: center;
		line-height: 15px;
		svg {
			cursor: pointer;
			width: 30px;
			height: 30px;
		}
	}

	.main-checked-container {
		display: flex;

		.title-section {
			display: flex;
			flex-direction: column;
			padding-inline-start: 20px;
			justify-content: center;

			.dots {
				max-width: 200px;
				display: flex;
				overflow: hidden;
				gap: 4px;
				.dot {
					width: 7px;
					min-width: 7px;
					height: 7px;
					border-radius: 50%;
				}
			}
			.title {
				min-width: max-content;
				font-size: 24px;
				font-weight: 100;
				line-height: 1.5;
			}
		}

		.actions-container {
			display: flex;
			font-size: rem(14px);
			gap: 20px;
			margin-inline: 80px 20px;

			.action-item {
				cursor: pointer;
				min-width: max-content;
				height: 100%;
				display: flex;
				justify-content: center;
				align-items: center;
				flex-direction: column;
				gap: 5px;
				&:hover {
					svg {
						color: $bg-clr1;
					}
				}

				svg {
					width: 26px;
					height: 26px;
				}
			}
		}
	}
}
