.workspace-container {
	z-index: 6500;
	position: relative;
	height: 100vh;
	background-color: $bg-clr3;
	border-inline-end: 1px solid $clr2;

	.icon-star {
		svg {
			fill: gold;
		}
	}

	.separator {
		height: 1px;
		width: 230px;
		background-color: $border-clr;
		margin-inline: auto;
	}

	.expandable-sidebar-container {
		&.expanded {
			.expandable-sidebar {
				width: 254px;
				height: 100%;
				left: 66px;
			}
		}

		.expandable-sidebar {
			height: 100%;
			transition: width 0.2s;
			background-color: $bg-clr3;
			width: rem(29px);

			ul {
				padding: 1em;
			}
		}

		.open {
			width: 254px;
			height: 100%;
			left: 66px;
		}
	}
}

.container {
	position: relative;

	.expand-btn {
		border: 1px solid $clr2;
		border-radius: 50%;
		height: 25px;
		width: 25px;
		background-color: $clr0;
		z-index: 6999;
		position: absolute;
		left: calc(100% - 14px);
		top: 20px;
		transition: width 0.3s;

		&.rotate-arrow-left {
			transform: rotate(0deg);
			left: auto;
			right: -12px;
		}

		svg {
			color: $clr3;
		}

		&:hover {
			border-radius: 2em;
			background-color: $bg-clr1;
			width: 33px;

			svg {
				color: $clr0;
			}
		}
	}
}

.favorites-title {
	margin-inline-start: 10px;
	display: flex;
	align-items: center;
	gap: 3px;
	margin-block-end: 7px;
}

.empty-favorites {
	display: flex;
	flex-direction: column;
	align-items: center;
	padding-inline: 12px;
	text-align: center;

	img {
		height: 80px;
		width: 80px;
	}

	span {
		font-size: rem(15px);
		font-weight: 700;
		color: $clr1;
	}

	p {
		color: $clr1;
		font-weight: 400;
	}
}
