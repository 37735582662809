.main-layout {
	display: grid;
	grid-template-columns: 20px 1fr 20px;

	> * {
		grid-column: 2;
	}

	&.full {
		grid-column: 1 /-1;
	}

	@include for-mobile-layout {
		grid-template-columns: minmax(20px, 1fr) minmax(auto, $mobile-breakpoint) minmax(20px, 1fr);
	}

	@include for-narrow-layout {
		grid-template-columns: $mobile-breakpoint;
	}

	@include for-narrow-layout {
		grid-template-columns: minmax(20px, 1fr) minmax(auto, $mobile-breakpoint) minmax(20px, 1fr);
	}

	@include for-normal-layout {
		grid-template-columns: minmax(20px, 1fr) minmax(auto, $narrow-breakpoint) minmax(20px, 1fr);
	}

	@include for-wide-layout {
		grid-template-columns: minmax(20px, 1fr) minmax(auto, $normal-breakpoint) minmax(20px, 1fr);
	}

	@include for-extra-wide-layout {
		grid-template-columns: minmax(20px, 1fr) minmax(auto, $extra-wide-breakpoint) minmax(20px, 1fr);
	}

	@include for-extra2-wide-layout {
		grid-template-columns: minmax(20px, 1fr) minmax(auto, $extra2-wide-breakpoint) minmax(20px, 1fr);
	}
}

.card-grid {
	display: grid;
	grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
	grid-gap: 20px;
}
