.task-preview {
	display: flex;
	height: $task-row-height;
	background-color: $clr0;
	z-index: 2;
	border-block-end: 1px solid $border-clr;
	&:hover {
		.sticky-container {
			background-color: $task-hover-bgc;
		}
	}

	.main-preview-container {
		display: flex;
		min-width: max-content;
		& > *:not(.line-end) {
			border-inline-end: 1px solid $border-clr;
		}
	}

	.sticky-container {
		z-index: 2;
		height: calc($task-row-height);
		border-block-end: 1px solid $border-clr;
		display: flex;
		position: sticky;
		left: var(--left-margin-size);
		background-color: $clr0;
		&:hover {
			background-color: $task-hover-bgc;
		}

		li {
			height: $task-row-height;
		}
	}

	.colored-border {
		margin-block-end: -2px;
		height: calc(100% + 1px);
		width: 6px;
		min-width: 6px;
	}

	&:hover {
		background-color: $task-hover-bgc;
		box-shadow: 4px 3px 6px -3px $input-hover-border-clr;

		.task-option {
			opacity: 1;
		}
	}

	.task-option-container {
		position: sticky;
		z-index: 30;
		left: 0px;
		height: 40px;
		width: var(--left-margin-size);
		justify-content: center;
		align-items: center;
		display: flex;
		background-color: $clr0;
	}

	.task-option {
		color: $clr1;

		display: flex;
		justify-content: center;
		align-items: center;
		width: 25px;
		height: 25px;
		position: relative;
		opacity: 0;

		svg {
			height: var(--options-size);
			width: var(--options-size);
		}
	}

	.label-picker {
		display: flex;
		justify-content: center;
		align-items: center;
	}
}
