.page-not-found {
	display: flex;
	flex-direction: column;
	align-items: center;
	margin-block-start: 150px;

	img {
		height: rem(174px);

		margin-block-end: 1rem;
	}

	h1 {
		font-size: rem(27px);
		font-weight: 500;
	}

	h2 {
		font-size: rem(15px);
		font-weight: 500;
	}
}
