.task-list-header {
	font-size: rem(14px);
	display: flex;
	border-start-start-radius: 4px;
	position: relative;
	border-block: 1px solid $border-clr;

	&.collapsed {
		border: none;
	}

	.empty-option-container {
		width: var(--left-margin-size);
		height: calc(var(--left-margin-size) + 4px);
		background-color: $clr0;
	}

	.sticky-container {
		position: sticky;
		left: 0px;
		background-color: $clr0;
		z-index: 2;
		border-start-start-radius: 4px;

		&.collapsed {
			border: none;
		}
	}

	.colored-border {
		margin-block: -2px;
		height: calc(100% + 2px);
		width: 6px;
		min-width: 6px;
		border-start-start-radius: 4px;
	}

	& > * {
		border-inline-end: 1px solid $border-clr;
		line-height: rem(23px);
		display: flex;
		flex-shrink: 0;
		justify-content: center;
		align-items: center;
		position: relative;

		&:hover {
			.resizing-container {
				opacity: 1;
			}
		}
	}

	.task-title-header {
		width: var(--left-side-width);
		user-select: none;
		height: $task-row-height;
		display: flex;
		align-items: center;
		justify-content: center;
	}

	.resizing-container {
		position: absolute;
		z-index: 10;
		width: 6px;
		right: -2px;
		cursor: col-resize;
		border-radius: 2px;
		height: $task-row-height;
		opacity: 0;
		background-color: $border-clr;

		&.is-dragging {
			background-color: $bg-clr1;
			opacity: 1;
		}

		&:hover {
			background-color: $bg-clr1;
			opacity: 1;
		}
	}
}

.line-end {
	border-inline: none;
	width: 250px;
}
