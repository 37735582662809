.board-loader {
	width: 100vw;
	height: 100vh;
	display: flex;

	.sidebar-loader {
		display: none;

		@media screen and (min-width: $mobile-breakpoint) {
			display: flex;
			flex-direction: column;
			position: relative;
			height: 100vh;
			color: $clr0;
			background-color: $bg-clr2;
			width: 66px;
			min-width: 66px;
		}
	}

	.main-loader-container {
		width: 100%;
		display: flex;
		align-items: center;
		justify-content: center;
	}
}
