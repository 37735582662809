.member-summary {
	display: flex;
	align-items: center;
	justify-content: center;
	.collaborator-img-container {
		.extra-members-box {
			background-color: $bg-clr6;
			color: $clr0;
			border-radius: 50%;
			font-size: rem(12px);
			width: 25px;
			height: 25px;
			display: flex;
			align-items: center;
			justify-content: center;
		}
		display: flex;
		align-items: center;
		justify-content: center;
		img {
			border-radius: 50%;
			min-width: 30px;
			min-height: 30px;
			max-width: 30px;
			max-height: 30px;
			border: 2px solid white;
			&:not(:last-child) {
				margin-inline-end: -6px;
			}
		}
	}
}
