.kanban-group-preview {
	width: 260px;
	border-start-start-radius: 8px;
	border-start-end-radius: 8px;
	display: flex;
	flex-direction: column;
	max-height: 100%;
	min-height: calc(100% - 60px);

	.group-title {
		border-start-start-radius: 8px;
		border-start-end-radius: 8px;

		min-height: 40px;
		height: 40px;
		font-size: rem(18px);
		color: $clr0;
		display: flex;
		padding-inline-start: 8px;
		align-items: center;
	}

	.kanban-task-list {
		flex: 1;
		max-height: 100%;
		height: 100%;
		overflow: auto;
		overflow-x: hidden;
		padding-block: 10px;
		display: flex;
		flex-direction: column;
		gap: 10px;
		border-end-end-radius: 8px;
		border-end-start-radius: 8px;
		background-color: $bg-clr3;

		&::-webkit-scrollbar {
			width: 4px;
		}

		&::-webkit-scrollbar-thumb {
			background: transparent;

			border-radius: 4px;
			transition: background 0.2s ease;
		}

		&:hover {
			&::-webkit-scrollbar-thumb {
				background: $input-hover-border-clr;
			}
			background-color: $button-hover-bgc;
		}
	}
}
