.home-page {
	.img-container {
		grid-column: 1 /-1;
		padding-block-start: em(30px);
		min-height: fit-content;

		img {
			width: 100%;
		}

		@media (min-width: $mobile-breakpoint) {
			padding-block-start: em(20px);
			min-height: em(411px);
			align-items: center;
		}
	}

	.sponsers {
		max-width: 770px;
		display: flex;
		flex-direction: column;
		justify-content: center;
		margin-inline: auto;
		padding-block-start: em(30px);

		@media (min-width: $mobile-breakpoint) {
			padding-block-start: em(70px);
		}

		h2 {
			text-align: center;
			font-size: rem(22px);
			padding-block-end: 0.5em;
			font-weight: 400;
			font-family: $title-font;
			color: $bg-clr6;

			@media (min-width: $mobile-breakpoint) {
				padding-block-end: 2em;
			}
		}

		.sponsers-list {
			display: flex;
			align-items: center;
			justify-content: center;
			flex-wrap: wrap;
			margin-block-end: 5em;

			li {
				width: em(150px);
				padding-block: 1.5em;
				display: flex;
				justify-content: center;

				img {
					width: em(110px);
				}

				&:nth-child(8) img {
					width: em(70px);
				}
			}
		}
	}

	.home-header {
		position: sticky;
		top: 0;
		z-index: 10;
		background-color: $clr0;
		width: 100%;
		box-shadow: 0 0 0 transparent;
		transition: box-shadow 0.3s ease;

		&.scrolled {
			box-shadow: 0px 4px 4px rgba(29, 140, 242, 0.078);
		}
	}

	.main-nav {
		justify-content: space-between;
		align-items: center;

		.logo {
			width: rem(168.5px);
			height: rem(42px);
		}

		ul {
			align-items: center;
			height: em(72px);

			li:not(:last-child) {
				width: auto;
				padding: 0.6em;
			}

			button {
				height: em(40px);
				display: none;
				padding: 8px 14px 8px 16px;

				.btn-title {
					padding-inline-end: 8px;
				}

				.btn-title,
				.btn-arrow {
					display: flex;
					font-size: rem(13px);
					color: $clr0;
					font-family: $title-font;
					font-weight: 300;
					transition: padding 0.4s;
				}

				&:hover .btn-arrow {
					padding-inline-start: 0.8em;
				}

				@media (min-width: $mobile-breakpoint) {
					display: flex;
					align-items: center;
				}
			}

			a {
				text-decoration: none;
				color: black;
				padding: 8px 10px;
				font-size: rem(14px);
				font-family: $title-font;
				font-weight: 300;
				color: $clr7;
				cursor: pointer;

				&:hover {
					color: #5034ff;
					background-color: $clr8;
					border-radius: 8px;
				}
			}
		}
	}
}

.home-main {
	.titles-container {
		display: flex;
		max-width: rem(375px);
		flex-direction: column;
		align-items: center;
		margin-inline: auto;
		text-align: center;
		font-family: $title-font;
		padding-block-start: 2em;
		color: $bg-clr6;

		@media (min-width: $mobile-breakpoint) {
			max-width: em(754px);
			padding-block-start: 4em;
		}

		span.main-title {
			font-size: rem(44px);
			font-weight: 600;
			padding-block-end: 0.55em;

			@media (min-width: $mobile-breakpoint) {
				font-size: 4.4rem;
			}
		}

		span.secondary-title {
			font-size: rem(22px);
			font-weight: 300;
		}
	}

	.card-container {
		margin-block: em(56px);
		width: 100%;

		.cards-section {
			display: flex;
			flex-wrap: wrap;
			justify-content: center;
			gap: 1em;

			@media (min-width: $mobile-breakpoint) {
				display: flex;
				justify-content: center;
			}
		}

		img {
			height: em(40px);
			width: em(40px);
		}

		.card {
			display: flex;
			flex-direction: column;
			align-items: center;
			text-align: center;
			width: em(120px);
			height: em(120px);
			margin: em(8px);
			padding: em(8px);
			padding-block-start: em(7px);
			border-radius: em(8px);
			border: 1px solid $input-hover-border-clr;

			.icon-container {
				flex-grow: 1;
				display: flex;
				align-items: flex-end;
			}

			span {
				flex-grow: 1;
				display: flex;
				align-items: flex-start;
				font-size: rem(14px);
				font-weight: 300;
				font-family: $title-font;
				color: $bg-clr6;
				opacity: 0.85;
				padding-inline: 8px;
				padding-block-start: 4px;
			}
		}
	}

	.btn-container {
		display: flex;
		flex-direction: column;
		align-items: center;

		button {
			height: em(49px);

			.btn-title {
				padding-inline-end: 8px;
			}

			.btn-title,
			.btn-arrow {
				font-size: rem(16px);
				color: $clr0;
				font-family: $title-font;
				font-weight: 300;
				transition: padding 0.4s;
			}

			&:hover .btn-arrow {
				padding-inline-start: 0.8em;
			}
		}

		span {
			font-size: rem(13px);
			font-weight: 300;
			font-family: $title-font;
			color: $icon-color;
			margin-block-start: 16px;
		}
	}
}
