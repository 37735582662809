.login {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	margin-inline: auto;

	.main-header {
		height: rem(65px);
		width: 100%;
		padding-inline-start: 20px;
		background-color: #f7f7f7;
		display: flex;
		align-items: center;

		img {
			cursor: pointer;
			height: 75%;
		}
	}

	h1 {
		margin-block-start: 80px;
		font-size: rem(40px);
		font-weight: 200;
		margin-block-end: 8px;
	}

	.input-container {
		display: flex;
		flex-direction: column;
		width: em(360px);
		margin-block-start: 28px;
		gap: 20px;

		label {
			color: $bg-clr6;
			align-self: center;
			margin-block-end: 4px;
			font-weight: 500;
		}

		input {
			font-size: rem(16px);
			padding: 6px 12px;
			color: #555;
			font-weight: 300;

			&::placeholder {
				color: $icon-color;
			}
		}

		button {
			height: 48px;
			margin-block-end: 32px;
			font-size: rem(18px);
			color: $clr0;
			border: none;
			background-color: $bg-clr1;
			padding: 8px 16px;
			line-height: 28px;
			border-radius: 4px;
			font-family: inherit;
			cursor: pointer;

			.btn-arrow {
				margin-inline-start: rem(8px);
			}

			&:hover {
				background-color: $bg-clr1-darker;
			}
		}
	}

	.separator-container {
		display: flex;
		align-items: center;
		gap: 16px;

		h2 {
			font-weight: 300;
			font-size: rem(16px);
			color: $bg-clr6;
		}

		.separator {
			width: 200px;
			background-color: $border-clr;
			height: 2px;
		}
	}

	.suggest-signup,
	.suggest-login {
		font-weight: 300;
		font-size: rem(16px);

		span {
			padding-inline-end: 5px;
		}

		a {
			color: #0fa2e2;
		}
	}
}
