// Font Sizes
.fs12 {
	font-size: rem(12px);
}

.fs14 {
	font-size: rem(14px);
}

.fs18 {
	font-size: rem(18px);
}

.fs20 {
	font-size: rem(20px);
}

.fs24 {
	font-size: rem(24px);
}

.fs28 {
	font-size: rem(28px);
}

.fs30 {
	font-size: rem(30px);
}

.title-font {
	font-family: $title-font;
}

.wght-400 {
	font-weight: 400;
}

.wght-700 {
	font-weight: 700;
}

.btn-primary {
	border-radius: 4px;
	cursor: pointer;

	&:hover {
		background-color: $button-hover-bgc;
	}

	&.active {
		background-color: $button-clicked-bgc;
	}
}

.clean-list {
	padding: 0;
	list-style: none;
}

// flex helpers

.flex {
	display: flex;

	>*.full {
		flex: 1;
	}

	&.column {
		flex-direction: column;
	}

	&.justify-center {
		justify-content: center;
	}

	&.justify-end {
		justify-content: flex-end;
	}

	&.space-between {
		justify-content: space-between;
	}

	&.space-evenly {
		justify-content: space-evenly;
	}

	&.space-around {
		justify-content: space-around;
	}

	&.align-center {
		align-items: center;
	}

	&.stretch {
		align-items: stretch;
	}

	&.wrap {
		flex-wrap: wrap;
	}

	&.gap-half {
		gap: 0.5em;
	}

	&.gap-1 {
		gap: 1em;
	}

	&.gap-3 {
		gap: 3em;
	}
}

// Sidebar helpers
.rotate-arrow-right {
	transform: rotate(180deg);
}

// Button helpers
.pointer {
	cursor: pointer;
}

.text-cursor {
	cursor: text;
}

// Div helpers
.w-100 {
	width: 100%;
}

.p-half-em {
	padding: 0.5em;
}

.p-1em {
	padding: 1em;
}

.p-16 {
	padding: rem(16px);
}

.pi-10 {
	padding-inline: 10px;
}

.mbe-1 {
	margin-block-end: 1em;
}

.default-scroll {
	&::-webkit-scrollbar {
		width: 10px;
		height: 15px;
	}

	&::-webkit-scrollbar-track {
		background: inherit;
	}

	&::-webkit-scrollbar-thumb {
		background: #b2b2b2;
	}
}