.board-nav-bar {
	display: flex;
	margin-block-end: 5px;
	border-block-end: 1px solid $border-clr;

	li {
		position: relative;
		display: flex;
		height: 100%;
		padding-block-end: 3px;

		&.active,
		&.active-page {
			border-block-end: 2px solid $bg-clr1;
		}

		&:hover:after {
			visibility: hidden;
		}
		&:not(:last-child)::after {
			content: '';
			position: absolute;
			top: 8px;
			right: -1px;
			height: 16px;
			align-self: center;
			border-inline-end: 1px solid $border-clr;
		}

		a {
			width: 100%;
			height: 100%;
		}

		div,
		a {
			padding: 5px 10px;
			span {
				svg {
					margin-inline-end: 5px;
				}
				display: flex;
				align-items: center;
				line-height: 24px;
				font-size: rem(14px);
				font-weight: 500;
			}
		}
	}
}
