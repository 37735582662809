.member-picker {
	cursor: pointer;
	display: flex;
	align-items: center;
	justify-content: center;

	img {
		border-radius: 50%;
		min-width: 30px;
		min-height: 30px;
		max-width: 30px;
		max-height: 30px;
		border: 2px solid white;
		border-radius: 50%;
	}

	.collaborator-img-container {
		display: flex;
		align-items: center;
		justify-content: center;
		.extra-members-box {
			background-color: $bg-clr6;
			color: $clr0;
			border-radius: 50%;
			font-size: rem(12px);
			width: 25px;
			height: 25px;
			display: flex;
			align-items: center;
			justify-content: center;
		}

		img {
			&:not(:last-child) {
				margin-inline-end: -6px;
			}
		}
	}

	.member-picker-modal {
		cursor: default;
		max-width: 360px;
		font-size: rem(14px);
		z-index: 99999;
		background-color: $clr0;
		border-radius: 8px;
		box-shadow: $default-box-shadow;
		padding: 0px 20px 8px 20px;

		.assigned-members {
			display: flex;
			flex-wrap: wrap;
			min-height: min-content;
			font-size: rem(12px);
			padding-block-start: 8px;

			.member {
				background-color: #e5f4ff;
				border-radius: 24px;
				display: flex;
				gap: 1px;
				align-items: center;
				margin: 4px;
				height: 22px;
				img {
					width: 22px;
					height: 22px;
					margin-inline-end: 4px;
				}

				.fullname-container {
					cursor: text;
					user-select: text;
				}

				.icon-container {
					cursor: pointer;
					&:hover {
						background-color: white;
						border-radius: 50%;
					}
					color: $icon-color;
					display: flex;
					align-items: center;
				}
			}
		}

		.scroll-container {
			margin-block: 8px;
			max-height: 220px;
			overscroll-behavior: none;
			overflow-x: visible;
			overflow-y: auto;

			&::-webkit-scrollbar {
				width: 4px;
			}

			&::-webkit-scrollbar-track {
				background: inherit;
			}

			&::-webkit-scrollbar-thumb {
				background: $input-hover-border-clr;
				border-radius: 4px;
			}
		}

		.input-container {
			margin-block-start: 16px;
			cursor: text;
			display: flex;
			align-items: center;
			justify-content: space-between;
			width: 320px;
			height: 32px;
			outline: 1px solid $input-hover-border-clr;
			border-radius: 4px;

			&:has(input:focus) {
				outline: 1px solid $bg-clr1;
			}

			&:hover:not(:focus) {
				outline: 1px solid $clr1;
			}
			.svg-container {
				width: 24px;
				height: 24px;
				display: flex;
				justify-content: center;
				align-items: center;
				cursor: pointer;
				.active-search {
					transform: translate(45deg);
				}

				color: $icon-color;
				margin-inline-end: 4px;
				display: flex;
				align-items: center;

				span {
					display: flex;
					align-items: center;
				}
				svg {
					width: 16px;
					height: 16px;
				}
				&.clear-icon {
					transform: rotate(45deg);
				}
			}
		}
		input {
			all: unset;
			height: 32px;
			padding: 8px 24px 8px 16px;
		}

		.suggested-people {
			display: flex;
			align-items: center;
			color: $icon-color;
			line-height: 24px;
			height: calc($task-row-height + 8px);
			position: sticky;
			top: 0;
			span {
				height: 100%;
				width: 100%;
				background-color: $clr0;
				padding-block-start: 8px;
				margin-block-end: 4px;
			}
		}

		.members-list {
			overflow-x: hidden;
			display: flex;
			flex-direction: column;

			li {
				height: 40px;
				min-height: 40px;
				display: flex;
				align-items: center;
				overflow: hidden;
				text-overflow: ellipsis;
				white-space: nowrap;
				width: 316px;
				padding: 8px;
				span {
					padding-inline: 8px;
				}

				img {
					height: 26px;
					width: 26px;
				}
			}
		}
	}
}
