.activity-details {
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	background-color: $clr0;
	position: absolute;
	right: 0;
	top: 0;
	width: 100%;
	z-index: 9999;
	box-shadow: $default-box-shadow;

	.dynamic-cmp > * {
		width: 100%;
		justify-content: space-evenly;
	}

	@media screen and (min-width: $mobile-breakpoint) {
		width: rem(650px);
		height: 100vh;

		display: grid;
		grid-template-columns: 20px 1fr 20px;
		grid-template-rows: 40px 60px 40px 1fr;
		grid-auto-flow: row;
		height: 100%;
		overflow-y: auto;
		overflow-x: hidden;
	}

	& > * {
		grid-column: 2;
	}

	.activity-list-container {
		grid-row: 4;
		display: flex;
		flex-direction: column;
	}

	.activity-details-title {
		font-family: $title-font;
		font-weight: 500;
		font-size: rem(18px);
		padding: 7px 7px;
		width: 80%;

		div {
			&:hover {
				outline: 1px solid $input-hover-border-clr;
				border-radius: 4px;
				cursor: text;
			}
		}

		.title-input {
			all: unset;
			margin: 0;
			width: 100%;

			&:focus {
				background-color: unset;
				outline: 1px solid $bg-clr1;
				border-radius: 4px;
			}
		}
	}

	.close-modal-btn {
		height: 10px;
		cursor: pointer;

		svg {
			margin-block-start: 10px;
			width: 30px;
			height: 30px;
			color: $clr10;
		}
	}
}

.details-back-panel:has(+ .activity-details:hover) {
	visibility: visible;
}

.details-back-panel {
	position: absolute;
	width: 100vw;
	height: 100vh;
	top: 0;
	left: 0;
	background-color: $transparent-overlay-clr;
	z-index: 7000;
	visibility: hidden;
}
