.filter-activity {
	height: 2em;
	margin-block-end: rem(16px);
	justify-content: flex-start;

	.input-container {
		height: 2em;
		border: 1px solid $input-border;
		border-radius: 4px;
		width: rem(200px);

		@media screen and (min-width: $mobile-breakpoint) {
			width: rem(150px);
		}

		input[type='search'] {
			all: unset;
			padding-inline: 16px;
			width: 100%;

			&::placeholder {
				font-size: 14px;
			}
		}
	}

	.filter-person {
		padding: 0.5em;
	}
}
