.board-filter {
	display: flex;
	gap: 1px;

	.member-container {
		position: relative;

		.empty-filter-btn {
			display: flex;
			align-items: center;
			margin-inline-end: 5px;
			background-color: $clr1;
			width: 10px;
			height: 10px;
			border-radius: 50%;
			color: $clr0;
		}

		.member-filter {
			width: 200px;
			height: fit-content;
			position: absolute;
			top: 38px;
			background-color: $clr0;
			display: flex;
			flex-direction: column;
			align-items: flex-start;
			gap: 5px;
			padding: 16px;
			box-shadow: $default-box-shadow;
			border-radius: 8px;

			span {
				&:first-child {
					font-size: 15px;
				}

				&:nth-child(2) {
					font-size: 14px;
					margin-block-end: 16px;
				}
			}

			.member-list {
				display: flex;
				gap: 5px;
				img {
					cursor: pointer;
					width: 30px;
					height: 30px;
					border-radius: 50%;
				}
			}
		}
	}

	.hide-container {
		position: relative;

		.hide-button {
			display: flex;
			align-items: center;
			height: 100%;
		}

		.hide-columns {
			top: 38px;
			left: 0;
			z-index: 99;
			background-color: $clr0;
			position: absolute;
			box-shadow: $default-box-shadow;
			border-radius: 8px;
			min-width: 264px;

			.hide-columns-title {
				padding: 16px;
				font-size: rem(15px);
			}

			.separator {
				height: 1px;
				width: 90%;
				background-color: $border-clr;
				margin-inline: auto;
			}

			.hide-columns-list {
				margin: 8px;
				.column-item {
					input {
						all: revert;
					}
					.icon-name-container {
						display: flex;
						gap: 8px;
						align-items: center;

						svg {
							width: 15px;
							height: 15px;
							display: flex;
						}
					}
					padding: 6px 16px;
					font-size: rem(14px);
					display: flex;
					justify-content: space-between;
				}
			}
		}
	}

	.search-container {
		padding-inline-start: 5px;

		width: 92px;
		transition: outline 0.1s;
		transition: width 0.1s;
		position: relative;

		&.focused,
		&:has(:focus) {
			&:not(.active):hover {
				background-color: inherit;
			}
			margin-inline-end: 10px;
			cursor: text;
			width: 235px;

			outline: 1px solid $bg-clr1;
			height: 100%;
			border-radius: 4px;
		}
		.empty-search-icon {
			display: flex;
			font-size: rem(12px);
			position: absolute;
			right: 5px;
		}
	}

	.filter-search-input {
		all: unset;
		width: 60px;
		width: 100%;
		height: 28px;
		font-size: em(14px);
		overflow: hidden;
		text-overflow: ellipsis;
		white-space: nowrap;
		padding-inline-start: 5px;
	}

	& > * {
		display: flex;
		align-items: center;
		.filter-text {
			padding-inline-end: em(12px);
			font-size: em(14px);
		}
	}

	.filter-icon {
		color: $icon-color;
		width: 32px;
		display: flex;
		align-items: center;
		justify-content: center;
	}

	svg {
		font-weight: lighter;
	}
}

.board-header-bottom {
	display: flex;
	padding-block: 16px;
}
