* {
	box-sizing: border-box;
	user-select: none;
}

html {
	font-family: Figtree, Roboto, Rubik, sans-serif;
	line-height: 1.15;
	color: $clr1;
	font-size: 14px;
}

body,
#root,
.app {
	margin: 0;
	height: 100%;
}

h1,
h2,
h3,
h4,
h5,
h6 {
	margin: 0.5em 0 0.5em;
}

img {
	height: 100%;
}

a {
	text-decoration: none;
	color: inherit;
}

.btn-get-started {
	@include btn-get-started;
}

ul {
	margin: 0;
}

img,
embed,
video,
object {
	max-width: 100%;
}

img,
embed,
video,
object {
	max-width: 100%;
}

@media screen and (min-width: $mobile-breakpoint) {
	:root {
		--options-size: 22px;
		--left-side-width: 400px;
		--left-margin-size: 36px;
		font-size: 16px;
	}
}

@media screen and (max-width: $mobile-breakpoint) {
	* {
		::-webkit-scrollbar {
			display: none;
			-webkit-appearance: none;
		}
	}
}
