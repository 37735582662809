.group-list {
	padding: 0px 0px 40px;

	@media (min-width: $mobile-breakpoint) {
		padding: 18px 0px 40px;
	}

	min-width: 1100px;
	.add-group-btn {
		position: sticky;
		// Ignore in regards to mq
		left: $task-row-height;
		border: 1px solid rgb(195, 198, 212);
		border-radius: 4px;
		height: em(32px);
		width: 141px;
		padding: 4px 8px;
		gap: 5px;
		align-items: center;
		cursor: pointer;

		.txt {
			color: rgb(50, 51, 56);
			font-size: 14px;
		}
	}
}
