.popper-container {
	z-index: 999;
}

.group-preview {
	font-size: em(18px);
	line-height: 24px;
	margin-block-end: 55px;
	border-radius: 4px;
	position: relative;
	min-width: max-content;

	.title-count-container {
		display: flex;
		align-items: center;
	}

	li:has(&) {
		position: relative;
	}

	&.collapsed {
		border-block: 1px solid $border-clr;

		.colored-border {
			display: none;
		}

		.collapsed-colored-border-top {
			width: 6px;
			height: calc(100% + 1px);
			margin-block-start: -1px;
			border-start-start-radius: 4px;
		}
		.group-sticky-container {
			position: static;
			display: flex;
			height: $task-row-height;
		}
		.header-container {
			border-inline-end: 1px solid $border-clr;
			background-color: $clr0;
			width: 480px;
			z-index: 2;
			position: sticky;
			left: 0;
		}
		.empty-option-container {
			display: none;
		}
		.group-header {
			.group-title-input {
				width: 80%;
			}
		}
	}

	.group-sticky-container {
		position: sticky;

		top: 185px;
		z-index: 40;
		background-color: $clr0;
	}

	.header-container {
		display: flex;
		justify-content: space-between;
		height: 100%;
		width: 100%;
	}

	.group-header {
		position: sticky;
		left: 0;
		z-index: 30;
		display: flex;
		align-items: center;
		.group-title-container {
			cursor: text;
			padding-inline: 4px;
			margin-block: 9px;
			position: relative;
			z-index: 1;

			.group-title {
				min-width: max-content;
				margin: 0;
				font-weight: 500;
				font-family: $title-font;

				&:hover {
					cursor: text;
					outline: 1px solid $input-hover-border-clr;
					border-radius: 4px;
				}
			}
		}

		.group-title-input {
			all: unset;
			font-weight: 500;
			font-size: rem(18px);
			font-family: $title-font;
			width: 80vw;

			&:focus {
				outline: 1px solid $bg-clr1;
				border-radius: 4px;
			}
		}

		.expand-arrow-container {
			width: 24px;
			height: 24px;
			display: flex;
			align-items: center;
			justify-content: center;

			position: relative;
			z-index: 1;
			cursor: pointer;
			border: none;
			margin-inline: 9px 2px;
			&.collapsed {
				transform: rotate(180deg);
			}
			transform: rotate(-90deg);
		}

		&:hover {
			.task-count {
				opacity: 1;
			}
			.group-option-container {
				.group-option {
					opacity: 1;
				}
			}
		}

		.task-count {
			margin-block-start: 3px;
			margin-inline-start: 8px;
			opacity: 0;
			font-size: rem(14px);
			font-weight: 400;
			color: $icon-color;
			transition: opacity 0.2s;
		}

		.group-option-container {
			width: var(--left-margin-size);
			height: 40px;
			color: $clr1;
			background-color: $clr0;
			position: relative;
			display: flex;
			justify-content: center;
			align-items: center;
			z-index: 50;

			.group-option {
				opacity: 0;
				svg {
					width: var(--options-size);
					height: var(--options-size);
				}
			}
		}
	}

	.drag-handle {
		flex: 1;
		user-select: none;
		width: 100%;
		opacity: 0;
		height: 40px;
	}

	.task-row {
		min-width: min-content;
		& > *:not(.task-option, .task-title-header, .task-title) {
			flex-shrink: 0;
			user-select: none;
			height: $task-row-height;
		}
	}
}

.color-picker {
	position: absolute;
	width: rem(125px);
	background-color: $clr0;
	border: 1px $input-hover-border-clr solid;
	border-radius: 4px;
	display: flex;
	flex-wrap: wrap;
	justify-content: flex-start;
	z-index: 100;

	.color {
		cursor: pointer;
		height: em(20px);
		width: em(20px);
		border-radius: 50%;
		margin: 3px;
	}
}
