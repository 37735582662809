.workspace-board-list {
	width: 254px;
	height: 100%;
	background-color: $bg-clr3;

	.workspace-title {
		height: 34px;
		margin-block: 15px 5px;
		color: $clr1;
		padding-inline-start: 16px;
		display: flex;
		gap: 6px;
		align-items: center;

		.workspace-icon {
			font-size: rem(14px);
			color: $clr0;
			width: 20px;
			height: 20px;
			display: flex;
			justify-content: center;
			align-items: center;
			border-radius: 6px;
			background-color: $success-color;
			position: relative;

			.house-icon {
				position: absolute;
				right: -5px;
				bottom: -8px;
			}
		}
	}

	.searchbox-wrapper {
		background-color: inherit;
		border: 1px solid $clr4;
		border-radius: 0.4em;
		height: 32px;
		width: rem(180px);
		position: relative;

		&:has(:focus) {
			background-color: $clr0;
		}

		svg {
			color: $clr1;
			flex: none;

			&:nth-of-type(1) {
				margin-left: 0.4em;
			}

			&:nth-of-type(2) {
				position: absolute;
				right: 0;
				margin-inline: 0.4em;
			}
		}

		input::placeholder {
			padding-inline-start: 0.5em;
			margin: 0 auto;
			font-size: rem(14px);
		}

		&:hover {
			border: 1px solid $clr1;
		}

		input[type='text'] {
			all: unset;
			border: none;
		}
	}

	.board-title-preview {
		font-size: rem(14px);
		justify-content: space-between;
		align-items: center;
		padding: 0.5em;
		height: rem(32px);
		gap: 0.5em;
		color: $clr1;
		border-radius: 0.4em;
		&:hover {
			.delete-board-icon {
				display: flex;
				align-self: flex-end;
			}
		}

		.board-name-container {
			display: flex;
			gap: 10px;
			align-items: center;
			text-overflow: ellipsis;
			overflow: hidden;
			svg {
				min-width: 19px;
				width: 19px;
				min-height: 19px;
				height: 19px;
			}

			.board-name {
				overflow: hidden;
				text-overflow: ellipsis;
				white-space: nowrap;
			}
		}

		a {
			display: flex;
			gap: rem(5px);
			overflow: hidden;

			svg {
				min-width: 20px;
				min-height: 20px;
			}

			span {
				text-overflow: ellipsis;
				overflow: hidden;
				white-space: nowrap;
			}
		}

		.delete-board-icon {
			display: none;
		}
	}

	.add-btn {
		cursor: pointer;
		color: $clr0;
		background-color: $bg-clr1;
		border-radius: 4px;
		width: 32px;
		height: 32px;
		display: flex;
		align-items: center;
		justify-content: center;

		&:hover {
			background-color: $bg-clr1-darker;
		}
	}
}
