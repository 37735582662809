.user-details {
	display: flex;
	flex-direction: column;

	.back-icon {
		rotate: 180deg;
		position: absolute;
		top: 20px;
		right: 20px;
		font-size: rem(25px);
		cursor: pointer;
	}

	.logout-icon {
		gap: 5px;
		position: absolute;
		top: 20px;
		left: 20px;
		font-size: rem(20px);
		cursor: pointer;
		display: flex;
		align-items: center;
		svg {
			font-size: rem(25px);
		}
	}

	.main-header {
		color: $clr0;
		background-color: $bg-clr1;
		display: flex;
		flex-direction: column;
		align-items: center;
		padding-block-start: 70px;
		box-shadow: 0 4px 8px -1px rgba(0, 0, 0, 0.2);

		.img-container {
			position: relative;
			height: 150px;
			width: 150px;

			&:hover .change-img-profile {
				opacity: 1;
			}

			img {
				border-radius: 50%;
				width: 100%;
				height: 100%;
				margin-block-end: 25px;
			}

			.change-img-profile {
				display: flex;
				flex-direction: column;
				justify-content: center;
				align-items: center;
				position: absolute;
				top: 0;
				background-color: $transparent-overlay-clr;
				border-radius: 50%;
				width: 100%;
				height: 100%;
				opacity: 0;
				cursor: pointer;

				transition: opacity 0.3s;

				svg {
					font-size: rem(56px);
				}

				span {
					font-size: rem(13px);
				}
			}
		}

		h1 {
			font-size: rem(30px);
			font-weight: 300;
			margin-block-end: 50px;
		}

		.main-nav {
			ul {
				li {
					padding: 0px 8px 8px 8px;
					margin-inline-end: 10px;
					position: relative;

					&:has(a.active) {
						border-block-end: $clr0 2px solid;
					}

					&::after {
						content: '';
						height: 2px;
						width: 100%;
						transform: scaleX(0);
						left: 0;
						bottom: 0;
						background-color: $clr0;
						position: absolute;
						transition: 0.2s all ease;
					}

					&:has(a:not(.active):hover)::after {
						transform: scaleX(1);
					}
				}
			}
		}
	}

	.personal-info {
		font-size: rem(15px);
		font-weight: 300;
		max-width: 550px;
		margin-inline: auto;
		padding-block: 30px;

		h2 {
			font-weight: 300;
			font-size: rem(18px);
		}

		ul {
			display: flex;
			flex-direction: column;
			gap: 10px;

			li {
				display: flex;
				align-items: center;

				.data-container {
					max-width: 430px;
					padding: 2px 14px 2px 5px;
					cursor: pointer;
					flex-grow: 1;
					padding: 4px 8px;
					border: 1px dashed transparent;

					&:hover {
						border: 1px dashed $border-clr;
					}

					.exists {
						color: $bg-clr1;
					}

					span:first-child {
						padding-inline-end: 7px;
					}
				}

				.icon-container {
					width: 40px;
					height: 40px;
					border-radius: 50%;
					border: $border-clr 1px solid;
					display: flex;
					align-items: center;
					justify-content: center;
					margin-inline-end: 5px;

					svg {
						font-size: 16px;

						&.bigger {
							font-size: 20px;
						}
					}
				}
			}
		}
	}

	.change-password {
		width: 100%;
		overflow-y: auto;
		height: calc(100vh - 360px);

		.form-container {
			max-width: 550px;
			margin-inline: auto;
			font-weight: 300;
			padding-block: 30px;
		}

		h1 {
			margin-block-start: 30px;
			font-weight: 300;
			font-size: rem(18px);
		}

		form {
			display: flex;
			flex-direction: column;

			label {
				width: 550px;
				display: flex;
				justify-content: space-between;
				align-items: center;
				font-size: rem(15px);
				line-height: 1;
				margin-block: 20px;
			}

			input {
				width: 370px;
				height: 34px;
				padding: 2px 0px 3px 6px;
			}

			.separator {
				height: 1px;
				background-color: $border-clr;
				margin-block: 32px;
			}

			button {
				align-self: flex-end;
				font-size: rem(16px);
				padding: 8px 16px;
				height: 40px;
				line-height: 24px;
				background-color: $bg-clr1;
				color: $clr0;
				border: none;
				border-radius: 4px;
				cursor: pointer;

				&:hover {
					background-color: $bg-clr1-darker;
				}
			}
		}
	}
}

.modal {
	position: absolute;
	left: 50%;
	top: 50%;
	translate: -50% -50%;
	box-shadow: $default-box-shadow;
	width: 500px;
	background-color: $clr0;
	padding: 30px;
	min-height: 200px;
	border-radius: 4px;

	.icon-close {
		position: absolute;
		cursor: pointer;
		top: 10px;
		right: 10px;

		svg {
			width: 25px;
			height: 25px;
			color: $icon-color;
		}
	}

	label {
		font-size: rem(25px);
		color: $bg-clr6;
		display: flex;
		flex-direction: column;

		span {
			margin-block-end: 14px;
		}
	}

	input {
		height: 34px;
		padding: 6px 12px;
		font-size: rem(14px);
		margin-block: 10px 16px;
	}

	.btn-container {
		display: flex;
		width: 100%;
		flex-direction: column;

		button {
			align-self: flex-end;
			font-size: rem(16px);
			padding: 8px 16px;
			height: 40px;
			line-height: 24px;
			background-color: $bg-clr1;
			color: $clr0;
			border: none;
			border-radius: 4px;
			cursor: pointer;
			margin-block-start: 28px;
		}
	}
}
