.user-msg {
	display: flex;
	justify-content: center;
	align-items: center;
	position: fixed;
	color: $clr0;
	background-color: grey;
	margin-inline: auto;
	margin-block-start: 25px;
	padding: 10px;
	min-width: 200px;
	max-width: max-content;
	border-radius: 4px;
	height: 50px;
	font-size: rem(14px);
	top: 0;
	right: 0;
	left: 0;
	z-index: 99999;

	&.success {
		background-color: $success-color;
	}
	&.error {
		background-color: $error-color;
	}

	button {
		color: inherit;
		background-color: inherit;
		margin-inline-start: 25px;
		float: right;
		border: 0;
		border-radius: 50%;
		display: flex;
		svg {
			height: 20px;
			width: 20px;
		}
	}
}
