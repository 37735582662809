.task-title {
	display: flex;

	align-items: center;
	justify-content: space-between;
	overflow: hidden;
	font-size: rem(14px);
	width: var(--left-side-width);
	flex-shrink: 0;
	user-select: none;
	.title-main-container {
		height: $task-row-height;
		width: 100%;
		display: flex;
		justify-content: space-between;
		align-items: center;
		text-overflow: ellipsis;
		overflow: hidden;
		white-space: nowrap;
	}

	.conversation-icon-container {
		cursor: pointer;
		height: 100%;
		border-inline-start: 1px solid $border-clr;
		color: $clr9;

		&.comments-available {
			color: $bg-clr1;
		}

		&:hover {
			color: $bg-clr1;
		}
		div {
			width: 100%;
			height: 100%;
			display: flex;
			align-items: center;
			justify-content: center;
			padding-inline: 16px;
			position: relative;

			.updates-count {
				cursor: pointer;
				position: absolute;
				top: 12px;
				background-color: $bg-clr1;
				border-radius: 250px;
				color: $clr0;
				font-size: rem(10px);
				padding: 2px 4px;
				line-height: 9px;
			}
		}
	}

	.open-task-details {
		align-items: center;
		height: 100%;
		right: 0px;
		display: none;

		.open {
			color: rgb(50, 51, 56);
			font-size: rem(13px);
		}

		.icon {
			font-size: rem(13px);
		}
	}

	&:hover {
		.open-task-details {
			margin-inline-end: 10px;
			display: flex;
			gap: 4px;
		}
	}

	span {
		margin: 6px 10px 6px 26px;
		padding: 2px 0px 3px;
		text-overflow: ellipsis;
		overflow: hidden;
		white-space: nowrap;
		cursor: text;

		&:hover {
			outline: 1px solid $input-hover-border-clr;
			border-radius: 4px;
			cursor: text;
		}
	}

	input {
		all: unset;
		cursor: text;
		width: 100%;
		margin: 6px 10px 6px 26px;

		&:focus {
			background-color: $clr0;
			outline: 1px solid $bg-clr1;
			border-radius: 4px;
		}
	}
}
